import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Email from "./email"
import Phone from "./phone"
import Link from "./link"

const Impressum = () => {
  const data = useStaticQuery(graphql`
    query ImpressumQuery {
      site {
        siteMetadata {
          fullTitle
          address
          email
          phone
          vorsitzender
        }
      }
    }
  `);
  return (
    <div className="my-4">
      <p>
        {data.site.siteMetadata.fullTitle}
        <br/>
        {data.site.siteMetadata.address}
      </p>
      <p>
        <Email email={data.site.siteMetadata.email}/>
        <br/>
        <Phone phone={data.site.siteMetadata.phone}/>
      </p>
      <p className="mt-4">
        <span style={{ fontWeight: 500 }}>1. Vorsitzender:</span><br/>
        { data.site.siteMetadata.vorsitzender }
      </p>
      <p>
        <span style={{ fontWeight: 500 }}>Haftungshinweis:</span>
        <br/>
        Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links.
        Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
      </p>
      {/* <p>
        <Link to="/vereinssatzung">Vereinssatzung</Link>
      </p> */}
      <p>
        <Link to="/vorstand">Vorstand</Link>
      </p>
      <p>
        <Link to="/training#trainer">Trainer</Link>
      </p>
      <p>
        <span style={{ fontWeight: 500 }}>Design & Umsetzung</span>
        <br/>
        Marten Sigwart (<a href={"https://marten.sigw.art"} rel={"noreferrer"} target={"_blank"}>Website</a>)
      </p>
    </div>
  );
}

Impressum.propTypes = {
}

Impressum.defaultProps = {
}

export default Impressum;
