import React from "react"

const MapContainer = () => (
  <div style={{
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    paddingTop: '56.25%' /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  }}>
    <iframe
      title="anfahrt"
      width="600"
      height="450"
      frameBorder="0" style={{
        border:0,
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        width: '100%',
        height: '100%'
      }}
      src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCp-nFZFrPQ57UUoAUH5bRGHrSmLsMaK7U
      &q=WTHC,Hamburg" allowFullScreen>
    </iframe>
  </div>
);

export default MapContainer;
