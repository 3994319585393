import React, { useEffect, useRef } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "react-bootstrap/Container"
import { graphql, useStaticQuery } from "gatsby"
import MapContainer from "../components/map-container"
import { scrollToRef } from "../utils/dom-utils"
import { globalHistory } from "@reach/router"
import Email from "../components/email"
import Phone from "../components/phone"
import Impressum from "../components/impressum"
import LinkBack from "../components/link-back"

const KontaktPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query KontaktQuery {
      site {
        siteMetadata {
          fullTitle
          address
          email
          phone
        }
      }
    }
  `)

  const openingRef = useRef(null)
  const anfahrtRef = useRef(null)
  const impressumRef = useRef(null)

  const navigateToHash = hash => {
    if (!hash) return
    switch (hash) {
      case "#oeffnungszeiten":
        scrollToRef(openingRef)
        break
      case "#anfahrt":
        scrollToRef(anfahrtRef)
        break
      case "#impressum":
        scrollToRef(impressumRef)
        break
      default:
        break
    }
  }

  setTimeout(() => navigateToHash(location.hash), 100)

  useEffect(() => {
    // whenever the route changes we change the tab depending on the hash of the url
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        const hash = globalHistory.location.hash
        navigateToHash(hash)
      }
    })
  })
  return (
    <Layout>
      <Seo title="Kontakt" />
      <Container
        className="text-center text-md-left"
        style={{ maxWidth: "900px" }}
      >
        <section>
          <p>
            <LinkBack to="/" label={"Homepage"} />
          </p>
          <h1>Kontakt</h1>
          <div className="my-4" style={{ fontSize: "1.1rem" }}>
            {/*<p>Wir wollen von Ihnen hören! Bei Fragen oder Anmerkungen schreiben Sie uns einfach unter:</p>*/}
            <p>
              {data.site.siteMetadata.fullTitle}
              <br />
              {data.site.siteMetadata.address}
            </p>
            <p>
              <Email email={data.site.siteMetadata.email} />
              <br />
              <Phone phone={data.site.siteMetadata.phone} />
            </p>
          </div>
        </section>
        <section>
          <h2 ref={openingRef}>Öffnungszeiten</h2>
          <p>
            Büro: Birgit Boye
            <br />
            Montags und Donnerstags von 9.00 bis 12.00 Uhr
          </p>
        </section>
        <section>
          <h2 ref={anfahrtRef}>Anfahrt</h2>
          <MapContainer />
        </section>
        <section>
          <h2 ref={impressumRef}>Impressum</h2>
          <Impressum />
        </section>
      </Container>
    </Layout>
  )
}

export default KontaktPage
